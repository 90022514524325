<template>
    <Toast />
    <div :class="['layout-inline-menu', {'layout-inline-menu-active': activeInlineProfile}]" v-if="!isMobile() && (isStatic() || isSlim() || isSidebar())">
        <a class="layout-inline-menu-action" @click="onChangeActiveInlineMenu">
            <img src="../src/assets/dp_pt.png" alt="avatar" style="width: 44px; height: 44px;">
            <span class="layout-inline-menu-text">{{ this.$store.state.nama_admin }}</span>
            <i class="layout-inline-menu-icon pi pi-angle-down"></i>
        </a>

        <transition name="layout-inline-menu-action-panel">
            <ul v-show="activeInlineProfile" class="layout-inline-menu-action-panel">
                <li class="layout-inline-menu-action-item">
                    <a @click="logout()">
                        <i class="pi pi-power-off pi-fw"></i>
                        <span>Logout</span>
                    </a>
                </li>
                <li class="layout-inline-menu-action-item">
                    <a @click="modalUbah = true">
                        <i class="pi pi-key pi-fw"></i>
                        <span>Change Password</span>
                    </a>
                </li>
                <!-- <li class="layout-inline-menu-action-item">
                    <a>
                        <i class="pi pi-cog pi-fw"></i>
                        <span>Settings</span>
                    </a>
                </li>
                <li class="layout-inline-menu-action-item">
                    <a>
                        <i class="pi pi-user pi-fw"></i>
                        <span>Profile</span>
                    </a>
                </li> -->
            </ul>
        </transition>

        <Dialog header="Change Password" v-model:visible="modalUbah" :breakpoints="{ '960px': '75vw' }" :style="{ width: '40vw' }" :modal="true">
            <div class="col-12 lg:col-12 p-fluid">
                <h5 class="p-text-secondary">Please insert new password</h5>
            </div>
            <div class="field grid col-12 p-fluid">
                <div class="col-12 md:col-10">
                    <InputGroup v-if="rahasia" class="w-full flex">
                        <InputText id="Nama" type="password" placeholder="New Password"
                            v-model.lazy="password_baru"
                        />
                        <InputGroupAddon>
                            <Button icon="pi pi-eye" @click="rahasia = false"/>
                        </InputGroupAddon>
                        
                    </InputGroup>
                    <InputGroup v-else class="w-full flex">
                        <InputText id="Nama" type="text"  
                            v-model.lazy="password_baru"
                        />
                        <InputGroupAddon>
                            <Button icon="pi pi-eye-slash" @click="rahasia = true"/>
                        </InputGroupAddon>
                        
                    </InputGroup>
                </div>
                <div class="col-12 md:col-10 mt-2">
                    <InputGroup v-if="rahasia_konfirm" class="w-full flex">
                        <InputText id="Nama" type="password" placeholder="Confirm Password"
                            v-model.lazy="konfirm_password"
                        />
                        <InputGroupAddon>
                            <Button icon="pi pi-eye" @click="rahasia_konfirm = false"/>
                        </InputGroupAddon>
                        
                    </InputGroup>
                    <InputGroup v-else class="w-full flex">
                        <InputText id="Nama" type="text"  
                            v-model.lazy="konfirm_password"
                        />
                        <InputGroupAddon>
                            <Button icon="pi pi-eye-slash" @click="rahasia_konfirm = true"/>
                        </InputGroupAddon>
                        
                    </InputGroup>
                </div>
            </div>
            <div class="flex col-12 lg:col-12 p-fluid">
                <Button type="button" label="Cancel" class="p-button-secondary" @click="modalUbah = false"></Button>
                <Button type="button" label="Update" class="p-button-danger ml-4" @click="simpan()" :disabled="password_baru != konfirm_password"></Button>
            </div>
            <template #footer>

            </template>
        </Dialog>
    </div>
</template>

<script>
	export default {
        name: 'AppInlineMenu',
        emits: ['change-inlinemenu'],
        props: {
            menuMode: String,
            activeInlineProfile: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                password_baru: null,
                konfirm_password: null,
                modalUbah: false,
                rahasia: true,
                rahasia_konfirm: true,
            };
        },
        methods: {
            logout() {
                let vm = this;
                this.$store.commit("clear_data");
                vm.$toast.add({ severity: 'success', summary: 'Konfirmasi', detail: 'Anda Telah Logout', life: 3000 });
                vm.$router.push({ path: "/" });
            },
            simpan(){
                let vm = this
                // console.log(vm.$store.state.user);
                let data_user = vm.$store.state.user
                vm.$axios.post("/user/ubah_password", {
                    id: data_user.id,
                    username: data_user.username,
                    password_baru: vm.password_baru
                }) 
                .then((res) => {
                    // console.log(res);
                    if (res.data.status == 200) {
                        if (res.data.message == 'sukses') {
                            vm.$toast.add({ severity: 'success', summary: 'Konfirmasi', detail: 'Berhasil Ganti Password, Silahkan Login Kembali', life: 4000 });
                            setTimeout(() => {
                                vm.modalUbah = false
                                vm.$router.push({ path: "/" });
                            }, 3000);
                        } else {
                            vm.$toast.add({ severity: 'warn', summary: 'Konfirmasi', detail: res.data.message, life: 3000 });
                        }
                    } else {
                        vm.$toast.add({ severity: 'warn', summary: 'Konfirmasi', detail: res.data.message, life: 3000 });
                    }
                })
                .catch((err) => {
                    vm.$toast.add({ severity: 'warn', summary: 'Konfirmasi', detail: err.message, life: 3000 });
                })
            },
            onChangeActiveInlineMenu(event) {
                this.$emit('change-inlinemenu', event);
                event.preventDefault();
            },
            isHorizontal() {
                return this.menuMode === 'horizontal';
            },
            isSlim() {
                return this.menuMode === 'slim';
            },
            isOverlay() {
                return this.menuMode === 'overlay';
            },
            isStatic() {
                return this.menuMode === 'static';
            },
            isSidebar() {
                return this.menuMode === 'sidebar';
            },
            isDesktop() {
                return window.innerWidth > 991;
            },
            isMobile() {
                return window.innerWidth <= 991;
            }
        }
	}
</script>

<style scoped>

</style>
