<template>
    <div class="layout-breadcrumb-container">
        <div class="layout-breadcrumb-left-items">
            <a href="#" class="menu-button" @click="onMenuButtonClick($event)" v-if="isStatic()">
                <i class="pi pi-bars"></i>
            </a>

            <!-- <Breadcrumb :model="items" class="layout-breadcrumb"></Breadcrumb> -->
            <Breadcrumb :model="items" class="layout-breadcrumb">
                <template #item="{ item }">
                    <div class="p-menuitem-link cursor-pointer">
                        <span v-if="item.icon" class="p-menuitem-text" @click="goTo(item.to)"><i :class="item.icon" style="font-size: 1.25rem"></i></span>
                        <span v-else class="p-menuitem-text" @click="goTo(item.to, item)">{{ item.label }}</span>
                    </div>
                </template>
            </Breadcrumb>
        </div>
        
        <div class="layout-breadcrumb-right-items">
            <!-- <Button type="button" icon="pi pi-fw pi-bell" class="p-button-outlined p-button-danger mr-4" @click="goDetail(data)"/> -->
			<!-- <a tabindex="0" class="search-icon" @click="breadcrumbClick">
				<i class="pi pi-search"></i>
			</a> -->

			<!-- <div class="search-wrapper" :class="{'active-search-wrapper': searchActive}">
				<span class="p-input-icon-left">
					<i class="pi pi-search"></i>
					<InputText placeholder="Search..." v-model="search" @click="inputClick"/>
				</span>
			</div>

			<span class="layout-rightmenu-button-desktop">
				<Button label="Today" icon="pi pi-bookmark"
					class="layout-rightmenu-button" @click="onRightMenuButtonClick()"></Button>
			</span>

        <span class="layout-rightmenu-button-desktop">
            <Button label="Today" icon="pi pi-bookmark" class="layout-rightmenu-button" @click="onRightMenuButtonClick()">{{ times }}</Button>
        </span>
        <span class="layout-rightmenu-button-mobile">
				<Button icon="pi pi-bookmark"
					class="layout-rightmenu-button" @click="onRightMenuButtonClick()"></Button>
			</span> -->
		</div>
    </div>
</template>

<script>
import moment from 'moment';
import 'moment/dist/locale/id';
export default {
    props: {
        menuMode: String,
        searchActive: {
            type: Boolean,
            default: false,
        },
        searchClick: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['menubutton-click', 'rightmenu-button-click', 'update:searchActive', 'update:searchClick'],
    data() {
        return {
            items: [],
            search: '',
            moment,
            now: '',
        };
    },
    watch: {
        $route() {
            this.watchRouter();
        },
    },
    created() {
        this.watchRouter();
        this.setTimer();
    },
    computed: {
        times() {
            return moment(this.now).format('lll');
        },
        last_query() {
            return this.$store.state.last_query && this.$store.state.last_query != {} ? this.$store.state.last_query : null;
        },
    },
    methods: {
        watchRouter() {
            if (this.$router.currentRoute.value.meta.breadcrumb) {
                this.items = [];
                const bc = this.$router.currentRoute.value.meta.breadcrumb;
                for (let pro in bc) {
                    // console.log(pro)
                    this.items.push(bc[pro]);
                }
            }
        },
        goTo(x) {
            // console.log(x, 'ini x');
            // console.log(this.last_query[`${x}`],'kene')
            // console.log(this.last_query,'wewe')
            let vm = this
            let queries = vm.last_query[`${x}`];
            if (queries) {
                vm.$router.replace({
                    path: x,
                    query: queries,
                });
            } else {
                vm.$router.push(x);
            }
            // this.$router.push(x);
        },
        setTimer() {
            let vm = this;
            let interval = setInterval(() => {
                vm.now = moment();
            }, 1000);
            vm.interval = interval;
            clearInterval(interval - 1);
        },
        onMenuButtonClick(event) {
            this.$emit('menubutton-click', event);
        },
        onRightMenuButtonClick() {
            this.$emit('rightmenu-button-click');
        },
        inputClick() {
            this.$emit('update:searchClick', true);
        },
        breadcrumbClick() {
            this.$emit('update:searchActive', true);
            this.$emit('update:searchClick', true);
        },
        isStatic() {
            return this.menuMode === 'static';
        },
    },
};
</script>

<style scoped>
::v-deep(.p-breadcrumb > ul > li.p-breadcrumb-chevron:first-of-type) {
    display: none;
}
</style>
